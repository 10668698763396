import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {
  AddressList,
  BrandAddr2,
  BrandAddr3,
  BrandAreaListPage,
  IsAreaSelected,
} from '@/store';

import styles from '../styles.module.scss';

export default function PopularArea() {
  const navigator = useNavigate();
  const setAddr2 = useSetRecoilState(BrandAddr2);
  const setAddr3 = useSetRecoilState(BrandAddr3);
  const addressList = useRecoilValue(AddressList);
  const setIsAreaSelected = useSetRecoilState(IsAreaSelected);
  const setBrandPage = useSetRecoilState(BrandAreaListPage);
  const updateAddress = (address2: string, address3: string) => {
    const addr2Code = addressList.find(o => o.address === address2)?.id;
    const addr3Code = addressList
      .find(o => o.address === address2)
      ?.child?.find(o => o.address === address3)?.id;
    setAddr2(addr2Code);
    setAddr3(addr3Code);
    setIsAreaSelected(true);
    setBrandPage(0);
    navigator('/brands');
  };
  return (
    <div className={styles.popularArea}>
      <h3 className={styles.popularAreaTitle}>인기 지역별 브랜드 모아보기</h3>
      <ul className={styles.popularAreaList}>
        <li
          className={styles.popularItem}
          onClick={() => updateAddress('성동구', '성수동1가')}
          style={{marginLeft: '20px'}}>
          <div className={`${styles.areaImg} ${styles.sengsu}`}></div>
          <div className={styles.txt}>성수</div>
        </li>
        <li
          className={styles.popularItem}
          onClick={() => updateAddress('용산구', '전체')}>
          <div className={`${styles.areaImg} ${styles.yongsan}`}></div>
          <div className={styles.txt}>용산</div>
        </li>
        <li
          className={styles.popularItem}
          onClick={() => updateAddress('강남구', '신사동')}>
          <div className={`${styles.areaImg} ${styles.apgujeong}`}></div>
          <div className={styles.txt}>압구정</div>
        </li>
        <li
          className={styles.popularItem}
          onClick={() => updateAddress('중구', '을지로3가')}>
          <div className={`${styles.areaImg} ${styles.eljiro}`}></div>
          <div className={styles.txt}>을지로</div>
        </li>
        <li
          className={styles.popularItem}
          onClick={() => updateAddress('종로구', '전체')}>
          <div className={`${styles.areaImg} ${styles.jongro}`}></div>
          <div className={styles.txt}>종로</div>
        </li>
        <li
          className={styles.popularItem}
          onClick={() => updateAddress('마포구', '망원동')}>
          <div className={`${styles.areaImg} ${styles.mangwon}`}></div>
          <div className={styles.txt}>망원</div>
        </li>
        <li
          className={styles.popularItem}
          onClick={() => updateAddress('용산구', '한강로1가')}>
          <div className={`${styles.areaImg} ${styles.samgakji}`}></div>
          <div className={styles.txt}>삼각지</div>
        </li>
        <li
          className={styles.popularItem}
          onClick={() => updateAddress('송파구', '잠실동')}>
          <div className={`${styles.areaImg} ${styles.jamsil}`}></div>
          <div className={styles.txt}>잠실</div>
        </li>
        <li
          className={styles.popularItem}
          onClick={() => updateAddress('영등포구', '문래동2가')}
          style={{marginRight: '20px'}}>
          <div className={`${styles.areaImg} ${styles.munre}`}></div>
          <div className={styles.txt}>문래</div>
        </li>
      </ul>
    </div>
  );
}
