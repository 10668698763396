import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Sentry from '@sentry/react';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot} from 'recoil';
import {NavermapsProvider} from 'react-naver-maps';

import reportWebVitals from './reportWebVitals';
import App from './App';
import './i18n';

const rootElement = document.getElementById('root') as HTMLElement;
if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: '',
    release: process.env.REACT_APP_VERSION,
    environment: process.env.NODE_ENV,
    normalizeDepth: 6,
    integrations: [
      new Sentry.Integrations.Breadcrumbs({console: true}),
      new Sentry.BrowserTracing(),
    ],
  });
}

const root = ReactDOM.createRoot(rootElement);
root.render(
  <RecoilRoot>
    <BrowserRouter basename={'/'}>
      <NavermapsProvider ncpClientId="6m7xk3g5yc">
        <App />
      </NavermapsProvider>
    </BrowserRouter>
    {/* <BrowserRouter basename={'/'}>
      <App />
    </BrowserRouter> */}
  </RecoilRoot>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
