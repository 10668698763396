import React, {useState} from 'react';
import {Capacitor} from '@capacitor/core';
import cc from 'classcat';
import {Swiper, SwiperSlide} from 'swiper/react';

import {isMobile} from '@/utils';

import styles from './styles.module.scss';

interface Props {
  images: string[];
  height: number;
  isScrolled: boolean;
}

export default function BrandImageCarousel({images}: Props) {
  const [isShow, setIsShow] = useState(false);
  const [selectedImg, setSelectedImg] = useState<number | undefined>(undefined);
  return (
    <>
      <div
        className={cc([
          styles.brandWrapper,
          {[styles.android]: Capacitor.getPlatform() === 'android'},
        ])}
        style={{
          background: '#fff',
          position: isMobile() ? 'fixed' : 'sticky',
          left: 0,
          top: 0,
          right: 0,
          zIndex: 1,
        }}>
        <img src={images?.[0]} className={styles.thumnailImg} />
        <button
          type="button"
          className={styles.showAllBtn}
          onClick={() => {
            setIsShow(true);
            document.body.style.overflow = 'hidden';
            setSelectedImg(undefined);
          }}>
          <span
            className={cc(['icon', 'add-picture-white-icon', styles.showIcon])}>
            &nbsp;
          </span>
          <span className={styles.txt}>전체보기</span>
          <span
            className={cc([
              'icon',
              'arrow-down-white-icon',
              styles.arrowIcon,
            ])}></span>
        </button>
      </div>

      {isShow && (
        <div className={styles.allImgWrapper}>
          <div className={styles.imgListArea}>
            {images?.map((o, i) => (
              <div
                key={`img-item-${i}`}
                onClick={() => {
                  setSelectedImg(i + 1);
                }}>
                <img src={o} alt="" />
              </div>
            ))}
          </div>
          {selectedImg ? (
            <>
              <div className={styles.selectedImgWrapper}>
                {images.length > 0 && (
                  <Swiper
                    initialSlide={selectedImg - 1 ?? 0}
                    className="squareSwiper"
                    slideToClickedSlide={true}
                    onRealIndexChange={(el: any) =>
                      setSelectedImg(el.activeIndex + 1)
                    }>
                    {images?.map((o, i) => (
                      <SwiperSlide key={`slide-item-${i}`}>
                        <div
                          className={styles['img-wrapper']}
                          key={`brand_image_carousel_${i}`}
                          data-order={`${i + 1}`}>
                          <img src={o} alt="" />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          <div className={styles.header}>
            {selectedImg ? (
              <>
                <button type="button" className={styles.close}>
                  <span
                    className={`${styles.closeIcon} close-white-icon`}
                    style={{cursor: 'pointer'}}
                    onClick={() => setSelectedImg(undefined)}></span>
                </button>

                <div
                  className={
                    styles.imageIndex
                  }>{`${selectedImg}/${images?.length}`}</div>
              </>
            ) : (
              <button
                type="button"
                className={styles.back}
                onClick={() => {
                  setIsShow(false);
                  document.body.style.overflow = 'inherit';
                }}>
                <span className={styles.backIcon}></span>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
