import React from 'react';
import cc from 'classcat';
import {useLocation, useNavigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {Capacitor} from '@capacitor/core';

import {user} from '../../../store/user';
import {likeBrand} from '../../../services/brands';
import styles from './bottom.module.scss';
import {ToastMessage, isOpenToast} from '../../../store';

interface Props {
  title?: string;
  internalLink?: string;
  clickShare: () => void;
  info: BrandListItemType | undefined;
  parentId: number;
  reload: (_brandInfo: BrandListItemType) => void;
}

export default function index({clickShare, info, parentId, reload}: Props) {
  const accessToken = useRecoilValue(user);
  const navigator = useNavigate();
  const location = useLocation();
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setOpenToastModal = useSetRecoilState(isOpenToast);
  const doLike = async () => {
    if (!accessToken) {
      navigator('/login', {
        replace: true,
        state: {
          history: location.state.prev,
          prevPath: `${location.pathname}${location.search}`,
        },
      });
      return;
    }
    await likeBrand(accessToken, parentId, !info?.isBookmark);
    if (!info?.isBookmark === true) {
      setToastMessage('스크랩이 완료되었습니다.');
      setOpenToastModal(true);
    }
    const _info = JSON.parse(JSON.stringify(info));
    _info.isBookmark = !_info.isBookmark;
    _info.bookmarkCount = _info.isBookmark
      ? _info.bookmarkCount + 1
      : _info.bookmarkCount - 1;
    reload(_info);
  };

  const toggleLike = () => {
    doLike();
  };

  return (
    <div
      className={cc([
        styles.wrapper,
        {[styles.ios]: Capacitor.getPlatform() === 'ios'},
      ])}>
      <button type="button" className={styles.btn} onClick={clickShare}>
        <span className={styles.shareIcon} />
        공유하기
      </button>
      <button
        type="button"
        className={cc([styles.btn, {[styles.on]: info?.isBookmark}])}
        onClick={toggleLike}>
        <span className={styles.scrapIcon} />
        스크랩하기
      </button>
    </div>
  );
}
