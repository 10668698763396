import React, {useEffect, useState, useRef, useMemo} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {Browser} from '@capacitor/browser';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import {Share} from '@capacitor/share';

import {MagazineBookMark, ToastMessage, isOpenToast} from '@/store';
import useKakao from '@/hooks/useKakao';
import {UserInfo, user} from '@/store/user';
import {doBookMark} from '@/services/magazine';
import useNative from '@/hooks/useNative';
import useLogger, {Action} from '@/hooks/useLogger';

import styles from '../ContentsFooter/styles.module.scss';

interface Props {
  detailInfo: MagazineItem;
  type: 'brand' | 'zip' | 'news' | 'issue';
  getContentsDetail?: (isUnRefresh?: boolean) => void;
  toggleOptionSelector?: (is: boolean) => void;
}

export default function PopupFooter({detailInfo, toggleOptionSelector}: Props) {
  const kakao = useKakao();
  const navigator = useNavigate();
  const sender = useLogger();
  const Echo = useNative();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const [isBookmark, setIsBookmark] = useRecoilState(MagazineBookMark);
  const userInfo = useRecoilValue(UserInfo);
  const userToken = useRecoilValue(user);
  // const [magazineId, setMagazineId] = useRecoilState(MagazineItemId);

  const [isOpenShare, setIsOpenShare] = useState<boolean>(false);

  const makeUrl = () => {
    const host =
      process.env.NODE_ENV !== 'development'
        ? 'https://knewnew.co.kr'
        : 'https://dev.knewnew.co.kr';
    return `${host}${window.location.pathname}`;
  };

  const copy = async () => {
    const {title, subtitle} = detailInfo;
    if (Capacitor.getPlatform() === 'web') {
      window.navigator.clipboard.writeText(`${window.location.href}`);
      setToastMessage('링크가 복사되었습니다.');
      setIsOpenToast(true);
    } else {
      await Share.share({
        title: `${title}`,
        text: `${subtitle}`,
        url: `${makeUrl()}`,
      });
    }
    setIsOpenShare(false);
  };

  const shareKakao = async () => {
    const {title, subtitle, imageUrl} = detailInfo;
    sender({
      _msg: 'floating_share_click',
      _screen: window.location.pathname,
      _action: Action.CLICK,
    });
    if (Capacitor.getPlatform() === 'web') {
      kakao.share({
        title,
        description: subtitle,
        imageUrl: imageUrl,
        link: window.location.href,
      });
    } else {
      await Echo.echo({
        value: `share*${title}*${subtitle}*${imageUrl}*${makeUrl()}*${`${window.location.pathname}`}`,
      });
    }
    setIsOpenShare(false);
  };

  const _doBookmark = async () => {
    try {
      sender({
        _msg: 'floating_like_click',
        _screen: window.location.pathname,
        _action: Action.CLICK,
      });
      if (userInfo.id == -1) {
        // setOpenLoginModal(true);
        navigator('/login', {
          state: {
            prevPath: `${location.pathname}${location.search}`,
          },
        });
      } else {
        const paths = window.location.pathname.split('/');
        const id = Number(paths[paths.length - 1]);
        const next = !isBookmark;
        await doBookMark(id, next, userToken);
        setIsBookmark(next);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const openBrowser = async (url: string) => {
    if (Capacitor.getPlatform() === 'web') {
      window.open(url, '_blank');
    } else {
      await Browser.open({
        url,
      });
    }
  };

  const isTooltipShow = useMemo(() => {
    return (
      !!detailInfo?.popupInfo?.msgTxt &&
      detailInfo?.popupInfo?.msgTxt.length > 0
    );
  }, [detailInfo]);

  const handleClickOutside = (event: any) => {
    if (wrapperRef && !wrapperRef?.current?.contains(event.target)) {
      setIsOpenShare(false);
    }
  };

  const buttonTxt = useMemo(() => {
    const status = detailInfo?.popupInfo?.status || '';
    let btnTxt = '구매하기';
    switch (status) {
      case '1_opened':
        btnTxt = '구매하기';
        break;
      case '2_before_open':
        btnTxt = 'COMING SOON';
        break;
      case '1_sold_out':
        btnTxt = '대기 신청하기';
        break;
      case '3_closed':
        btnTxt = '판매 종료';
        break;
    }
    return btnTxt;
  }, [detailInfo]);

  const isActiveButton = useMemo(() => {
    return detailInfo.popupInfo?.status
      ? detailInfo.popupInfo?.status.indexOf('3_closed') === -1
      : false;
  }, [detailInfo]);

  useEffect(() => {
    setIsBookmark(detailInfo.isBookmark as boolean);
  }, [detailInfo]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.popup_footer}>
      {isTooltipShow && (
        <div className={styles.tooltipWrap}>
          <div className={styles.tooltip}>{detailInfo?.popupInfo?.msgTxt}</div>
        </div>
      )}

      <div
        className={styles.share_items_left_panel}
        style={{display: isOpenShare ? 'block' : 'none'}}
        ref={wrapperRef}>
        <div className={styles.share_item} onClick={() => shareKakao()}>
          <span className={styles.kakao_icon}>&nbsp;</span>
          <span className={styles.txt}>카카오톡 공유</span>
        </div>
        <div className={styles.share_item} onClick={() => copy()}>
          <span className={styles.link_copy_icon}>&nbsp;</span>
          <span className={styles.txt}>링크 공유</span>
        </div>
      </div>
      <button className={styles.extra_btn}>
        <span
          className={styles.share}
          onClick={() => setIsOpenShare(!isOpenShare)}>
          &nbsp;
        </span>
      </button>
      <button className={styles.extra_btn} onClick={() => _doBookmark()}>
        <span className={`${styles.bookmark} ${isBookmark && styles.active}`}>
          &nbsp;
        </span>
      </button>
      <button
        className={styles.buyButton}
        disabled={
          detailInfo.popupInfo
            ? detailInfo.popupInfo.status === '3_closed'
            : true
        }
        onClick={() => {
          sender({
            _msg: 'buy_click1',
            _screen: window.location.pathname,
            _action: Action.CLICK,
          });
          const soldoutLink =
            detailInfo.popupInfo?.status === '1_sold_out' && !userToken
              ? '/login'
              : `/order/emptyoptions/${detailInfo.id}`;
          !detailInfo.link
            ? detailInfo.popupInfo?.status === '1_sold_out'
              ? navigator(soldoutLink, {
                  state: {
                    detailInfo,
                    prevPath: `${location.pathname}${location.search}`,
                  },
                })
              : toggleOptionSelector && toggleOptionSelector(true)
            : openBrowser(detailInfo.link);
        }}
        style={{
          backgroundColor: isActiveButton ? '#000' : '#E9E7EC',
        }}>
        {buttonTxt}
      </button>
    </div>
  );
}
