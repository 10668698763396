import React, {useEffect, useRef, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {Keyboard, KeyboardResize} from '@capacitor/keyboard';
import {Capacitor} from '@capacitor/core';

import NoResult from '@/assets/imgs/no-result-search.svg';
import Layout from '@/layouts';
import ContentsHeader from '@/layouts/ContentsHeader';
import {user, ToastMessage, isOpenToast} from '@/store';
import useSlack from '@/hooks/useSlack';

import styles from './styles.module.scss';

export default function RecommendBrands() {
  const offsetTop = useRef(0);
  const [brandName, setBrandName] = useState('');
  const [reason, setReason] = useState('');
  const token = useRecoilValue(user);
  const sender = useSlack();
  const setToastMessage = useSetRecoilState(ToastMessage);

  const setOpenToastModal = useSetRecoilState(isOpenToast);
  const navigator = useNavigate();

  const apply = () => {
    sender({_msg: `브랜드이름: ${brandName} \n사유: ${reason}`});

    if (!token) {
      location.href = '/login';
    } else {
      navigator('/search');
      setToastMessage('신청이 완료되었습니다');
      setOpenToastModal(true);
    }
  };

  const initKeyboard = () => {
    Keyboard.addListener('keyboardWillShow', info => {
      console.log('keyboard will show with height:', info.keyboardHeight);
      const mode: KeyboardResize = KeyboardResize.Body; // 타입 정의에 맞는 값 설정
      Keyboard.setResizeMode({
        mode: mode,
      });
    });

    Keyboard.addListener('keyboardDidShow', info => {
      window.scrollTo(0, offsetTop.current + info.keyboardHeight);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      console.log('keyboard will hide');
    });

    Keyboard.addListener('keyboardDidHide', () => {
      console.log('keyboard did hide');
    });
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      initKeyboard();
    }
  }, []);

  return (
    <Layout.Main>
      <div>
        <ContentsHeader
          txt={'브랜드 추천하기'}
          isOption={'none'}
          isPopup={true}
        />
        {/* 이미지 */}
        <div className={styles.no_result_img}>
          <img src={NoResult} />
        </div>
        {/* 타이틀 */}
        <h3 className={styles.no_result_tit}>찾으시는 브랜드가 없으신가요?</h3>
        {/* 상세 */}
        <div className={styles.recommend_desc}>
          뉴뉴에 아직 등록되지 않았지만 <br />
          추천하고 싶은 브랜드가 있다면 알려주세요
        </div>
        <div className={styles.divider}></div>
        <div className={styles.brandInput}>
          <h3>브랜드명</h3>
          <input
            type="text"
            placeholder="추천하는 브랜드명을 입력해주세요"
            onChange={($e: React.ChangeEvent<HTMLInputElement>) =>
              setBrandName($e.target.value)
            }
          />
        </div>
        <div className={styles.brandInput}>
          <h3>추천하는 이유</h3>
          <input
            type="text"
            placeholder="추천하는 이유를 알려주세요"
            onChange={($e: React.ChangeEvent<HTMLInputElement>) =>
              setReason($e.target.value)
            }
          />
        </div>
        <div className={styles.footer}>
          <button
            className={styles.cancelBtn}
            onClick={() => navigator('/search')}>
            취소
          </button>
          <button
            className={styles.apply}
            disabled={brandName.length === 0 || reason.length === 0}
            onClick={() => apply()}>
            신청
          </button>
        </div>
      </div>
    </Layout.Main>
  );
}
