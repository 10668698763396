import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {Capacitor} from '@capacitor/core';
import {Share} from '@capacitor/share';
import {useTranslation} from 'react-i18next';

import Layout from '@/layouts';
import ContentsHeader from '@/layouts/ContentsHeader';
import ButtonFooter from '@/layouts/ButtonFooter';
import {
  getContentsDetail,
  getMagazineDetail,
  requestPayments,
} from '@/services/magazine';
import {UserInfo, user} from '@/store/user';
import Fetcher from '@/components/common/Fetcher';
import MarketingModal from '@/components/brandAlertModal/MarketingModal';
import {generateRandomString} from '@/utils';
import ProductOptionSelector from '@/components/common/ProductOptionSelector/ProductOptionSelector';
import {ToastMessage, isOpenToast} from '@/store';
import useKakao from '@/hooks/useKakao';
import useNative from '@/hooks/useNative';

import styles from './styles.module.scss';

export default function BenefitInfoPage() {
  const navigator = useNavigate();
  const location = useLocation();
  const Echo = useNative();
  const params = useParams();
  const kakao = useKakao();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const accessToken = useRecoilValue(user);
  const userInfo = useRecoilValue(UserInfo);
  const [isOpenShare, setIsOpenShare] = useState<boolean>(false);
  const [detailInfo, setDetailInfo] = useState();
  const [isOpenMarketingModal, setIsOpenMarketingModal] = useState(false);
  const [details, setDetails] = useState<MagazineItem>();
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const [isOpenOptionSelector, setIsOpenOptionSelector] = useState(false);
  const {t} = useTranslation(['views'], {keyPrefix: 'BenefitInfoPage'});
  const [btnInfo, setBtnInfo] = useState({
    txt: t('btnInfoTxt'),
    disabled: false,
  });
  const callToFree = async (optionId: number, options: string[]) => {
    const orderId = generateRandomString();
    const requestParam: PaymentRequestParam = {
      popup: location.state.benefit.id,
      orderName: location.state.benefit.title,
      paymentKey: orderId,
      option: optionId,
      orderId,
      purchaseCount: 1,
      purchaseAmount: 0,
    };
    await requestPayments(requestParam, accessToken);
    navigator(`/benefit/${location.state.benefit.id}/success`, {
      state: {
        benefit: location.state.benefit,
        options,
      },
    });
  };

  const makeUrl = () => {
    const host =
      process.env.NODE_ENV !== 'development'
        ? 'https://knewnew.co.kr'
        : 'https://dev.knewnew.co.kr';
    return `${host}${window.location.pathname}`;
  };

  const toggleOpenSelector = async (is: boolean) => {
    if (is && !userInfo.email) {
      navigator('/login', {
        state: {
          prevPath: `${location.pathname}${location.search}`,
        },
      });
      return;
    }
    if (is) {
      await getMagazineItem();
    }
    setIsOpenOptionSelector(is);
  };

  const getMagazineItem = async () => {
    const id = location.state?.benefit.id;
    const info: MagazineItem = await getContentsDetail(accessToken, id);
    setDetails(info);
  };

  const copy = async () => {
    const {title} = details as MagazineItem;
    if (Capacitor.getPlatform() === 'web') {
      window.navigator.clipboard.writeText(`${makeUrl()}`);
      setToastMessage(t('shareTxt'));
      setIsOpenToast(true);
    } else {
      await Share.share({
        title: `${title}`,
        text: `${title}`,
        url: `${makeUrl()}`,
      });
    }
    setIsOpenShare(false);
  };

  const shareKakao = async () => {
    const {title, imageUrl, subtitle} = details as MagazineItem;
    if (Capacitor.getPlatform() === 'web') {
      kakao.share({
        title,
        description: subtitle.substring(0, 50),
        imageUrl: imageUrl,
        link: window.location.href,
      });
    } else {
      await Echo.echo({
        value: `share*${title}*${subtitle.substring(
          0,
          50,
        )}*${imageUrl}*${makeUrl()}*${`${window.location.pathname}`}`,
      });
    }
    setIsOpenShare(false);
  };

  const getData = async () => {
    const id = Number(params.benefitId);
    const res = await getContentsDetail(accessToken, id);
    const content = await getMagazineDetail(accessToken, id);
    changeBtnInfo(res);
    setDetails(res);
    return content;
  };

  const isDimmed = useMemo(() => {
    const isSoldout = location.state.benefit.status === '1_sold_out';
    return !userInfo.isMarketing && isSoldout;
  }, [details, location]);

  const changeBtnInfo = (_details: MagazineItem) => {
    const info = {
      txt: t('btnDefault'),
      disabled: false,
    };
    const isSoldout = location.state.benefit.status === '1_sold_out';
    console.log(isSoldout);
    if (_details?.isBought) {
      info.txt = t('btnHasBought');
      info.disabled = true;
    } else if (userInfo.isMarketing && isSoldout) {
      info.txt = t('btnSoldout');
      info.disabled = true;
    } else if (!userInfo.isMarketing && isSoldout) {
      info.txt = t('btnSoldout');
      info.disabled = true;
    }
    setBtnInfo(info);
  };
  useEffect(() => {
    scrollTo(0, 0);
  }, []);
  return (
    <Layout.Main>
      <div className={styles.bottomWrapper}>
        <ContentsHeader
          txt={t('headerTxt')}
          isOption={'home'}
          isBottomLine={''}
          back={() => {
            const isBenefitDetail =
              location.state?.prev &&
              location.state?.prev.indexOf('order/benefit') > -1;
            if (isBenefitDetail) {
              navigator(`/${location.state?.prev}`, {
                state: {
                  item: location.state?.benefit,
                  status: location.state?.status,
                  prev: location.state?.postPrev,
                },
                replace: true,
              });
            } else {
              if (location.state?.prev) {
                navigator(`/${location.state?.prev}`, {
                  replace: true,
                  state: {
                    prev: location.state?.postPrev,
                  },
                });
              } else {
                navigator(-1);
              }
            }
          }}
        />
        <Fetcher fetcher={getData} setter={setDetailInfo}>
          <div style={{position: 'relative'}}>
            <div
              className={styles.dimmed}
              style={{display: isDimmed ? 'flex' : 'none'}}>
              <div className={styles.txt}>
                {t('deemedTxt1')} <br />
                {t('deemedTxt2')} <br />
                {t('deemedTxt3')}
              </div>
              <button
                className={styles.ringBtn}
                onClick={() => {
                  return setIsOpenMarketingModal(true);
                }}>
                <span className={styles.ring}></span>
                {t('alertButton')}
              </button>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: detailInfo || '',
              }}></div>
          </div>
        </Fetcher>
        <ButtonFooter
          wrapperStyle={{
            position: details ? 'sticky' : 'absolute',
            height: Capacitor.getPlatform() === 'ios' ? '92px' : '76px',
          }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              padding: '13px 0',
            }}>
            <div
              className={styles.share_items_left_panel}
              style={{
                display: isOpenShare ? 'block' : 'none',
                bottom: Capacitor.getPlatform() === 'ios' ? '106px' : '86px',
              }}
              ref={wrapperRef}>
              <div className={styles.share_item} onClick={() => shareKakao()}>
                <span className={styles.kakao_icon}>&nbsp;</span>
                <span className={styles.txt}>{t('shareKakao')}</span>
              </div>
              <div className={styles.share_item} onClick={() => copy()}>
                <span className={styles.link_copy_icon}>&nbsp;</span>
                <span className={styles.txt}>{t('shareLink')}</span>
              </div>
            </div>
            <button
              className={styles.shareBtn}
              onClick={() => setIsOpenShare(!isOpenShare)}>
              <span className={styles.share}>&nbsp;</span>
            </button>
            <button
              className={styles.funcBtn}
              disabled={btnInfo.disabled}
              onClick={() => {
                if (!accessToken) {
                  navigator('/login', {
                    state: `${location.pathname}${location.search}`,
                  });
                  return;
                }
                toggleOpenSelector(true);
              }}>
              {btnInfo.txt}
            </button>
          </div>
        </ButtonFooter>
        <ProductOptionSelector
          select={() => ''}
          isOpen={isOpenOptionSelector}
          togglePopup={toggleOpenSelector}
          magazineId={(details as MagazineItem)?.id}
          magazine={details as MagazineItem}
          options={(details as MagazineItem)?.popupInfo?.options}
          buyBenefit={(selectedOptionId: number, options?: string[]) =>
            callToFree(selectedOptionId, options || [])
          }
          buyBtnTxt={btnInfo.txt}
        />
        <MarketingModal
          isOpen={isOpenMarketingModal}
          setIsOpen={setIsOpenMarketingModal}
        />
      </div>
    </Layout.Main>
  );
}
