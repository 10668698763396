import React, {useState} from 'react';
import {Capacitor} from '@capacitor/core';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {
  BrandAddr2,
  BrandAddr3,
  BrandAreaListPage,
  IsAreaSelected,
  isOpenAddressSelector,
  user,
} from '@/store';
import BrandLoginModal from '@/components/common/BrandLoginModal';

import Fetcher from '../../../components/common/Fetcher';
import styles from './styles.module.scss';
import {getQuickMenus} from '../../../services/brands';

export default function QuickMenu() {
  interface QuickMenu {
    id: number;
    image: string;
    type: string;
    content: string;
    isActive: boolean;
    priority: number;
    link: string;
    isInternal: boolean;
  }
  const navigator = useNavigate();
  const accessToken = useRecoilValue(user);
  const setIsOpenAddress = useSetRecoilState(isOpenAddressSelector);
  const setIsAreaSelected = useSetRecoilState(IsAreaSelected);
  const setBrandAddr2 = useSetRecoilState(BrandAddr2);
  const setBrandAddr3 = useSetRecoilState(BrandAddr3);
  const setBrandsPage = useSetRecoilState(BrandAreaListPage);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [quickMenu, setQuickMenu] = useState<QuickMenu[]>();
  const getQuickMenu = async () => {
    const res = await getQuickMenus();
    console.log(quickMenu);
    return res.data;
  };

  const openModal = () => {
    if (!accessToken) {
      setIsOpenModal(true);
    } else {
      setIsOpenAddress(true);
    }
  };

  return (
    <Fetcher fetcher={getQuickMenu} setter={setQuickMenu}>
      <div
        className={`${styles.quick_menu} ${
          Capacitor.getPlatform() === 'android'
            ? styles.android
            : Capacitor.getPlatform() === 'ios'
            ? styles.ios
            : ''
        }`}>
        <div className={styles.brandTitle}>
          뉴뉴가 선별한 F&B 브랜드를 발견해 보세요
        </div>
        <div className={styles.brandLinkWrapper}>
          <div
            className={styles.brandLink}
            onClick={() => {
              setBrandsPage(0);
              setIsAreaSelected(false);
              setBrandAddr2(16);
              setBrandAddr3(undefined);
              navigator('/brands');
            }}
            style={{marginRight: '8px'}}>
            <div className={styles.linkTxtWrapper}>
              <div style={{textAlign: 'left'}}>내주변</div>
              <div className={styles.linkDesc}>
                현위치에서
                <br />
                브랜드 찾기
              </div>
            </div>
            <div className={styles.linkIconWrapper}>
              <span className={styles.linkIconLocation}></span>
            </div>
          </div>
          <div className={styles.brandLink} onClick={() => openModal()}>
            <div className={styles.linkTxtWrapper}>
              <div style={{textAlign: 'left'}}>지역별</div>
              <div className={styles.linkDesc}>
                원하는 지역의
                <br />
                브랜드 찾기
              </div>
            </div>
            <div className={styles.linkIconWrapper}>
              <span className={styles.linkIconArea}></span>
            </div>
          </div>
        </div>
        <div>
          <button
            className={styles.bannerLinkBtn}
            onClick={() => navigator('/collection/list')}>
            <span style={{display: 'flex', alignItems: 'center'}}>
              <span className={styles.shop}>SHOP</span>
              <span>케이크・베이커리・전통주</span>
            </span>
            <span className={styles.shop_arrow}></span>
          </button>
        </div>
        <BrandLoginModal
          isOpen={isOpenModal}
          setIsOpen={(is: boolean) => setIsOpenModal(is)}
          action={() => navigator('/login')}
        />
      </div>
    </Fetcher>
  );
}
