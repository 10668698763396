import {AppUpdate} from '@capawesome/capacitor-app-update';
import {App} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';

import {getLastVersion} from '../services/app';

export default function useAppStore() {
  const getCurrentAppVersion = async () => {
    let ret;
    if (Capacitor.getPlatform() === 'android') {
      const {version} = await App.getInfo();
      ret = version;
    } else {
      const {currentVersion} = await AppUpdate.getAppUpdateInfo();
      ret = currentVersion;
    }
    return ret;
  };

  const getAvailableAppVersion = async () => {
    if (Capacitor.getPlatform() === 'android') {
      const ret = (await getLastVersion()).data as AppVersionInfo;
      return ret.versionName;
    } else {
      const {availableVersion} = await AppUpdate.getAppUpdateInfo();
      return availableVersion;
    }
  };

  const openAppStore = async () => {
    await AppUpdate.openAppStore();
  };
  return {
    getCurrentAppVersion,
    getAvailableAppVersion,
    openAppStore,
  };
}
