import React, {useMemo} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useLocation, useNavigate} from 'react-router-dom';

import {AddressList, ToastMessage, isOpenToast, user} from '@/store';
import {likeBrand} from '@/services/brands';
import {BrandGiftItem} from '@/components/home/KnewBrand/components/BrandGiftItem';

import styles from '../styles.module.scss';

interface Props {
  info: BrandListItemType | undefined;
  openSeparators: () => void;
  parentId: number;
  reload: (_brandInfo: BrandListItemType) => void;
  benefits: CollectionListItem[];
}

export default function DetailInformation({
  info,
  openSeparators,
  parentId,
  reload,
  benefits,
}: Props) {
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setOpenToastModal = useSetRecoilState(isOpenToast);
  const accessToken = useRecoilValue(user);
  const addressList = useRecoilValue(AddressList);
  const navigator = useNavigate();
  const location = useLocation();
  const findAddressObj = (id: number, list: Address[]): Address => {
    let addressInfo: Address = {
      id: -1,
      address: '',
      child: [],
    };
    list.forEach(address => {
      if (id === address.id) {
        addressInfo = address;
      }
    });
    return addressInfo;
  };

  const doLike = async () => {
    if (!accessToken) {
      navigator('/login', {
        replace: true,
        state: {
          history: location.state.prev,
          prevPath: `${location.pathname}${location.search}`,
        },
      });
      return;
    }
    await likeBrand(accessToken, parentId, !info?.isBookmark);
    if (!info?.isBookmark === true) {
      setToastMessage('스크랩이 완료되었습니다.');
      setOpenToastModal(true);
    }
    const _info = JSON.parse(JSON.stringify(info));
    _info.isBookmark = !_info.isBookmark;
    _info.bookmarkCount = _info.isBookmark
      ? _info.bookmarkCount + 1
      : _info.bookmarkCount - 1;
    reload(_info);
  };

  const toggleLike = () => {
    doLike();
  };

  const shortAddress = useMemo(() => {
    let address1Item;
    let address2Item;
    if (addressList.length > 0 && info?.address1) {
      address1Item = '서울시';
      address2Item = findAddressObj(info?.address2, addressList);
      // address2Item = findAddressObj(info?.address2, address1Item?.child || []);
      return `${address1Item} ${address2Item.address}`;
    }
    return '';
  }, [addressList, info]);

  return (
    <div>
      <div className={styles.detailInformation}>
        <div className={styles.infoWrapper}>
          <div className={styles.info}>
            <div className={styles.txtWrapper} style={{margin: '0px 0px'}}>
              <div className={`${styles.title}`}>{info?.title}</div>
              <div className={`${styles.areaWrapper} `}>
                <div className={styles.area}>{shortAddress}</div>
                <div
                  className={styles.divider}
                  style={{
                    display:
                      info?.childPlaces && info?.childPlaces.length > 1
                        ? 'flex'
                        : 'none',
                  }}></div>
                <div
                  className={styles.areaSelector}
                  onClick={() => openSeparators()}
                  style={{
                    display:
                      info?.childPlaces && info?.childPlaces.length > 1
                        ? 'flex'
                        : 'none',
                    cursor: 'pointer',
                  }}>
                  <span className={styles.selectedArea}>
                    {info?.separator || ''}
                  </span>
                  <span className={styles.areaDropDown}>
                    <span className={styles.dropdownIcon}></span>
                  </span>
                </div>
              </div>
              <div className={styles.likeWrapper}>
                <span
                  className={`${styles.likeIcon} ${
                    info?.isBookmark ? styles.filled : ''
                  }`}
                  onClick={toggleLike}></span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.descriptions}>{info?.subtitle}</div>
        {info?.hashtags && info.hashtags.length > 0 && (
          <div className={styles.tagsWrapper}>
            {info.hashtags.map((item, index) => (
              <div key={index} className={styles.tag}>{`#${item}`}</div>
            ))}
          </div>
        )}
        {benefits.length > 0 && <BrandGiftItem item={benefits[0]} />}
      </div>
    </div>
  );
}
