import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router-dom';

import {user} from '@/store';
import {getNewBrands} from '@/services/brands';

import styles from '../styles.module.scss';

export default function PopularBrands() {
  const navigator = useNavigate();
  const token = useRecoilValue(user);
  const [recommendBrands, setRecommendBrands] = useState([]);
  const getRecommendBrandData = async () => {
    const res = await getNewBrands(
      token,
      {
        limit: 9999,
        offset: 0,
        sort: 'famous',
      },
      'search_popular',
    );
    setRecommendBrands(res.data.results);
  };
  const moveTo = (id: number, parentId: number) => {
    navigator(`/brand/${parentId}/${id}`);
  };
  useEffect(() => {
    getRecommendBrandData();
  }, []);
  return (
    <div className={styles.popularBrands}>
      <h3 className={styles.tit}>지금 많이 찾는 브랜드</h3>
      <div className={styles.popBrandsContainer}>
        {recommendBrands.map((brand: any) => {
          return (
            <span
              className={styles.brandInfo}
              onClick={() => moveTo(brand.id, brand.parentId)}
              key={`recommend_brand_${brand.id}`}
              style={{cursor: 'pointer'}}>
              <span className={styles.brandName}>{brand.title}</span>
              <span className={styles.brandCategory}>∙ {brand.keyword}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
}
