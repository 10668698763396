import React from 'react';

import Layout from '../layouts';
import {isMobile} from '../utils';

interface Props {
  children: React.ReactElement;
}

const Container = () => {
  return <div></div>;
};

const Home = ({children}: Props) => {
  return (
    <Layout.Main style={{opacity: 0}}>
      <div>
        <Layout.Header isTransparent isTicket isNoRing={!isMobile()} />
        {children}
        <Layout.BottomNavigation />
      </div>
    </Layout.Main>
  );
};

Container.Home = Home;

export default Container;
