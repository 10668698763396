import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import cc from 'classcat';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {requestOpen} from '@/services/brands';
import {isOpenToast, ToastMessage, user} from '@/store';

import styles from './collectionList.module.scss';

interface Props {
  collections: CollectionListItem[];
  brandName: string;
  brandId: number;
}

export default function index({collections, brandName, brandId}: Props) {
  const navigator = useNavigate();
  const accessToken = useRecoilValue(user);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const toggleSetting = async () => {
    await requestOpen(accessToken, brandId);
    setToastMessage('요청이 접수되었습니다.');
    setIsOpenToast(true);
  };

  const goTo = (id: number) => {
    navigator(`/popup/${id}`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>SELECT SHOP</div>
      <div className={styles.collections}>
        <Swiper
          className="collectionSwiper"
          initialSlide={0}
          spaceBetween={8}
          slidesPerView={'auto'}
          centeredSlides={true}
          centeredSlidesBounds={true}
          slideToClickedSlide={true}
          // slidesOffsetBefore={20}
          slidesOffsetAfter={20}>
          {collections?.map((item, index) =>
            item.status === '2_before_open' ? (
              <></>
            ) : (
              <SwiperSlide key={`slide-item-${index}`}>
                <div className={cc([styles.item])}>
                  <div className={styles.imgWrapper}>
                    <img
                      src={
                        item.collectionImageUrl
                          ? item.collectionImageUrl
                          : item.imageUrl
                      }
                    />
                  </div>
                  {(item.status === '1_opened' ||
                    item.status === '1_sold_out') && (
                    <div
                      className={styles.txtWrapper}
                      onClick={() => goTo(item.id)}
                      style={{cursor: 'pointer'}}>
                      <div className={styles.title}>{item?.bannerTitle}</div>
                      <div className={styles.status}>{item.info}</div>
                      <button type="button" className={styles.btn}>
                        구매하기
                      </button>
                    </div>
                  )}
                  {item.status === '3_closed' && (
                    <div
                      className={styles.txtWrapper}
                      onClick={() => goTo(item.id)}
                      style={{cursor: 'pointer'}}>
                      <div className={styles.title}>{item?.bannerTitle}</div>
                      <div className={styles.status}>{item.info}</div>
                      <button
                        type="button"
                        className={styles.btn}
                        onClick={() => goTo(item.id)}>
                        자세히보기
                      </button>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ),
          )}
          <SwiperSlide key={`slide-item-${collections.length}`}>
            <div className={cc([styles.item, styles.new])}>
              <div className={styles.imgWrapper}></div>
              <div className={styles.txtWrapper}>
                <div className={styles.title}>{`상품이\n준비중입니다`}</div>
                <div className={styles.status}>
                  {`${brandName}의 상품이 궁금하다면\n아래 버튼을 눌러 표현해주세요!`}
                </div>
                <button
                  type="button"
                  className={styles.btn}
                  onClick={toggleSetting}>
                  <span className={`icon ${styles.setup_bell}`}>&nbsp;</span>
                  <span>상품 오픈 원해요</span>
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* <div style={{padding: '30px 20px 40px'}}>
        <button
          className={styles.collectionViewMoreBtn}
          onClick={() => navigator(`/collection/list`)}>
          <span className={styles.txt}>다른 상품 보기</span>
          <span className={styles.collectionViewMoreIcon}></span>
        </button>
      </div> */}
    </div>
  );
}
