import React, {Suspense, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {AxiosResponse} from 'axios';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import cc from 'classcat';

import BrandImageCarousel from '@/components/brandImageCarousel';
import DetailInformation from '@/components/Brand/DefaultInformation';
import Layout from '@/layouts';
import PlaceInfo from '@/components/Brand/PlaceInfo';
import RecommendBrand from '@/components/Brand/RecommendBrand';
import OverlayHeader from '@/layouts/OverlayHeader';
import BrandShareBottomSheet from '@/components/brandShareBottomSheet';
import MarketingModal from '@/components/brandAlertModal/MarketingModal';
import {user} from '@/store/user';
import {getBrandBenefits, getBrandInfo} from '@/services/brands';
import Fetcher from '@/components/common/Fetcher';
import SeparatorSelector from '@/components/Brand/SeparatorSelector';
import {isMobile} from '@/utils';
import useLogger, {Action} from '@/hooks/useLogger';
import InstallAlert from '@/components/installAlert';

import MagazineInfo from '../../../components/Brand/MagazineInfo';
import CollectionList from '../../../components/Brand/CollectionList';
import BrandBottom from '../../../components/Brand/BrandBottom';
import styles from './styles.module.scss';

export default function Place() {
  const navigator = useNavigate();
  const params = useParams();
  const [openInstallAlert, setOpenInstallAlert] = useState(false);
  const [brandInfo, setBrandInfo] = useState<BrandListItemType>();
  const [benefits, setBenefits] = useState<CollectionListItem[]>([]);
  const [collectionBenefits, setCollectionBenefits] = useState<
    CollectionListItem[]
  >([]);
  const [brands, setBrands] = useState<BrandListItemType[]>([]);
  const [isOpenShareSheet, setIsOpenShareSheet] = useState(false);
  const [isOpenSeparatorSheet, setIsOpenSeparatorSheet] = useState(false);
  const [isOpenMarketingModal, setIsOpenMarketingModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [benefitParams, setBenefitParams] = useState<BrandBenefitParams>({
    limit: 20,
    offset: 0,
    category: 'benefits',
  });
  const [collectionParam, setCollectionParam] = useState<BrandBenefitParams>({
    limit: 20,
    offset: 0,
  });
  const accessToken = useRecoilValue(user);
  const [separators, setSeparators] = useState<
    {
      id: number;
      area: string;
    }[]
  >([]);
  const [args, setArgs] = useState({
    parentId: params.id,
    id: params.placeId,
  });
  const changeBrandInfo = (_brandInfo: BrandListItemType) => {
    setBrandInfo({
      ..._brandInfo,
    });
    scrollTo(0, 0);
  };
  const getBenefits = async (
    parentId: number,
    _brandInfo: BrandListItemType | undefined,
  ) => {
    const _params = {...benefitParams};
    const _collectionParams = {...collectionParam};
    _params.brand = _brandInfo?.id;
    _params.category = 'benefits';
    _collectionParams.brand_group = parentId;
    setBenefitParams(_params);
    setCollectionParam(_collectionParams);
    const res = await getBrandBenefits(accessToken, _params);
    const collectionRes = await getBrandBenefits(
      accessToken,
      _collectionParams,
    );
    setBenefits(res.data.results);
    setCollectionBenefits(collectionRes.data.results);
  };

  const sender = useLogger();
  const getData = async () => {
    const {id, placeId} = params;
    const res: AxiosResponse<BrandParentInfo> = await getBrandInfo(
      accessToken,
      Number(id || -1),
    );

    sender({
      _msg: 'brand_click',
      _screen: window.location.pathname,
      _action: Action.CLICK,
    });

    const parentId = res.data.id;
    const placeInfo: BrandListItemType | undefined = res.data.brands.find(
      b => b.id === Number(placeId || -1),
    );
    if (placeInfo) {
      getBenefits(parentId, placeInfo);
      setBrands(res.data.brands);
      // setBrandItems(res.data.brands);
      setSeparators(
        res.data.brands.map(o => {
          return {
            id: o.id,
            area: o.separator || '',
          };
        }),
      );
      placeInfo.thumbnail = res.data.thumbnail || '';
      return {
        ...placeInfo,
        parentId,
        subtitle: res.data.subtitle,
        bookmarkCount: res.data.bookmarkCount,
        isBookmark: res.data.isBookmark,
        childPlaces: res.data.brands,
      } as BrandListItemType;
    }
    return {} as BrandListItemType;
  };
  const onScroll = () => {
    if (window.scrollY > 10) {
      setIsScrolled(() => true);
    } else {
      setIsScrolled(() => false);
    }
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  }, []);

  useEffect(() => {
    if (params.placeId && brands.length > 0 && brandInfo) {
      if (brandInfo.id !== Number(params.placeId)) {
        const info = brands.find(b => b.id === Number(params.placeId));
        setTimeout(() => {
          setBrandInfo({
            ...brandInfo,
            ...info,
          });
          setArgs({
            parentId: `${params.id}`,
            id: `${params.placeId}`,
          });
        }, 0);
      }
    }
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [params, brands, brandInfo]);
  return (
    <Layout.Main>
      <div
        className={cc([
          styles.wrapper,
          {[styles.ios]: Capacitor.getPlatform() === 'ios'},
        ])}>
        <OverlayHeader
          isScrolled={isScrolled}
          title={brandInfo?.title}
          clickShare={() => setIsOpenShareSheet(true)}
          back={() => navigator(-1)}
        />
        <Fetcher fetcher={getData} setter={setBrandInfo} args={args}>
          {brandInfo && (
            <div>
              <BrandImageCarousel
                images={brandInfo?.imageList ? brandInfo?.imageList : []}
                height={375}
                isScrolled={isScrolled}
              />
              <div
                className={cc([
                  {[styles.isMobile]: isMobile()},
                  {[styles.ios]: Capacitor.getPlatform() === 'ios'},
                ])}
                style={{
                  background: '#F7F7FC',
                  position: 'relative',
                  zIndex: '9',
                }}>
                <DetailInformation
                  info={brandInfo}
                  openSeparators={() => setIsOpenSeparatorSheet(true)}
                  parentId={Number(params?.id || -1)}
                  reload={(brandInfo: BrandListItemType) => {
                    changeBrandInfo(brandInfo);
                    setArgs({
                      parentId: `${brandInfo.parentId}`,
                      id: `${brandInfo.id}`,
                    });
                  }}
                  benefits={benefits}
                />
                {/* 컬렉션 */}
                <CollectionList
                  collections={collectionBenefits}
                  brandName={brandInfo?.title || ''}
                  brandId={brandInfo?.id || -1}
                />
                {/* 매거진 */}
                <MagazineInfo args={args} />
                <PlaceInfo
                  placeInfo={{
                    address: brandInfo?.address || '',
                    openingHours: (brandInfo?.openingHours || '').split(
                      '\\n',
                    ) || [''],
                    brandTel: brandInfo?.brandTel || '',
                    brandLink: brandInfo?.brandLink || '',
                    reserveLink: brandInfo?.reserveLink || '',
                    name: brandInfo?.title || '',
                  }}
                />
                <Suspense>
                  <RecommendBrand
                    brandId={brandInfo?.id || -1}
                    item={brandInfo as BrandListItemType}
                    reload={(brandInfo: BrandListItemType) => {
                      changeBrandInfo(brandInfo);
                      setArgs({
                        parentId: `${brandInfo.parentId}`,
                        id: `${brandInfo.id}`,
                      });
                    }}
                  />
                </Suspense>
              </div>
              <BrandShareBottomSheet
                isOpen={isOpenShareSheet}
                setIsOpen={setIsOpenShareSheet}
                item={brandInfo as BrandListItemType}
              />
              <MarketingModal
                isOpen={isOpenMarketingModal}
                setIsOpen={setIsOpenMarketingModal}
              />
              <SeparatorSelector
                isOpen={isOpenSeparatorSheet}
                setIsOpen={setIsOpenSeparatorSheet}
                separators={separators}
                parentId={brandInfo?.parentId || 0}
                reload={(brandInfo: BrandListItemType) => {
                  changeBrandInfo(brandInfo);
                  setArgs({
                    parentId: `${brandInfo.parentId}`,
                    id: `${brandInfo.id}`,
                  });
                }}
              />
              <BrandBottom
                clickShare={() => setIsOpenShareSheet(true)}
                info={brandInfo}
                parentId={Number(params?.id || -1)}
                reload={(brandInfo: BrandListItemType) => {
                  changeBrandInfo(brandInfo);
                  setArgs({
                    parentId: `${brandInfo.parentId}`,
                    id: `${brandInfo.id}`,
                  });
                }}
              />
            </div>
          )}
        </Fetcher>
        <InstallAlert
          ignoreRead={openInstallAlert}
          setIgnore={setOpenInstallAlert}
        />
      </div>
    </Layout.Main>
  );
}
