import Axios from 'axios';

import {errorInterceptor} from '../utils';

export interface CollectionParam {
  limit?: number;
  category?: 'brands' | 'zip' | 'news';
  offset?: number;
  target?: string;
  status?: string;
  brand_group?: number;
  popup_type?: string;
  monthly?: number;
}

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.response.use(undefined, errorInterceptor.bind(this));

export const getMagazine = (
  token: string | undefined,
  limit?: number,
  category?: 'brands' | 'zip' | 'news',
  offset?: number,
  target?: string,
  brand_category?: string,
) => {
  return token
    ? axios.get('/magazine/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit: limit ?? 40,
          category,
          offset: offset ?? 0,
          target,
          brand_category,
        },
      })
    : axios.get('/magazine/', {
        params: {
          limit: limit ?? 40,
          category,
          offset: offset ?? 0,
          target,
          brand_category,
        },
      });
};

export const getBrandRecommends = () => {
  return axios.get('/magazine/collection/?type=magazine', {
    params: {
      limit: 9999999,
    },
  });
};

export const getCuratorInfo = (id: number) => {
  return axios
    .get(`/user/editors/${id}`)
    .then(response => response.data)
    .catch(err => err.response);
};

export const getCuratorsMagazines = (id: number, limit?: number) => {
  return axios
    .get(`/user/${id}/magazine/`, {
      params: {
        limit: limit ?? 20,
      },
    })
    .then(response => response.data)
    .catch(error => error.response);
};

export const getRelatedMagazines = (_categories: string[]) => {
  return axios
    .get(`/magazine/related/`, {
      params: {
        limit: 5,
        categories: _categories.join(','),
      },
    })
    .then(response => response.data)
    .catch(error => error.response);
};

export const getBookmarkMagazines = (
  userToken: string | undefined,
  id: number,
  limit?: number,
  category?: string,
  offset?: number,
) => {
  return userToken
    ? axios
        .get(`/user/${id}/bookmark/magazine/`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            limit,
            category,
            offset: offset ?? 0,
            remove_count: 'true',
          },
        })
        .then(response => response.data)
        .catch(error => error.response)
    : axios
        .get(`/user/${id}/bookmark/magazine`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            limit,
            category,
            offset: offset ?? 0,
            remove_count: 'true',
          },
        })
        .then(response => response.data)
        .catch(error => error.response);
};

export const getBookmarkProducts = (
  userToken: string | undefined,
  id: number,
  limit?: number,
  category?: string,
  offset?: number,
) => {
  return userToken
    ? axios
        .get(`/user/${id}/bookmark/product/`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            limit,
            category,
            offset: offset ?? 0,
          },
        })
        .then(response => response.data)
        .catch(error => error.response)
    : axios
        .get(`/user/${id}/bookmark/product`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            limit,
            category,
            offset: offset ?? 0,
          },
        })
        .then(response => response.data)
        .catch(error => error.response);
};
export const getRandomMagazines2 = (
  token: string | undefined,
  category: string,
  myid?: number,
) => {
  return token
    ? axios.get('/magazine/random2/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit: 10,
          category,
          myid: myid,
        },
      })
    : axios.get('/magazine/random2/', {
        params: {
          limit: 10,
          category,
        },
      });
};

export const getRandomMagazines = (token: string | undefined) => {
  return token
    ? axios.get('/magazine/random/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit: 100,
        },
      })
    : axios.get('/magazine/random/', {
        params: {
          limit: 100,
        },
      });
};

export const getContentsDetail = (token: string | undefined, id: number) => {
  return token
    ? axios
        .get(`/magazine/${id}/info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => response.data)
        .catch(err => console.log(err.response))
    : axios
        .get(`/magazine/${id}/info`)
        .then(response => response.data)
        .catch(err => console.log(err.response));
};

export const getMagazineDetail = (token: string | undefined, id: number) => {
  return token
    ? axios
        .get(`/magazine/${id}/`, {
          headers: {
            Accept: 'text/html',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => response.data)
        .catch(error => () => {
          console.log(error.response);
        })
    : axios
        .get(`/magazine/${id}/`, {
          headers: {
            Accept: 'text/html',
          },
        })
        .then(response => response.data)
        .catch(error => () => {
          console.log(error.response);
        });
};

export const getBookmarks = (id: number) => {
  return axios
    .get(`/user/${id}/magazine/`)
    .then(response => response.data)
    .catch(error => {
      console.log(error.response);
    });
};

export const getCurators = (sort = 0, limit = 20) => {
  return axios
    .get('/user/editors/?sort=' + sort + '&limit=' + limit)
    .then(response => response.data)
    .catch(error => console.log(error));
};

export const getHomeBanners = (accessToekn: string) => {
  return accessToekn
    ? axios.get(`/magazine/collection/?type=banner_main`, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      })
    : axios.get(`/magazine/collection/?type=banner_main`);
};

export const getMagazineBanners = () => {
  return axios.get(`/magazine/collection/?type=banner_magazine`);
};

export const getMagazineCollectionProducts = (accessToekn: string) => {
  return accessToekn
    ? axios.get('/magazine/collection/product/', {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      })
    : axios.get('/magazine/collection/product/');
};

export const getMagazineCollectionRecommend = (accessToekn: string) => {
  return accessToekn
    ? axios.get(`/magazine/collection/ordered/?type=recommend_home_main`, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      })
    : axios.get(`/magazine/collection/ordered/?type=recommend_home_main`);
};

export const getMagazineCollectionCategory = (accessToekn: string) => {
  return accessToekn
    ? axios.get(`/magazine/collection/ordered/?type=recommend_home_sub`, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      })
    : axios.get(`/magazine/collection/ordered/?type=recommend_home_sub`);
};

export const getMagazineCollectionItems = (
  accessToekn: string,
  type: string,
  offset?: number,
) => {
  return accessToekn
    ? axios.get(
        `/magazine/collection/ordered/?type=${type}&offset=${offset || 0}`,
        {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
          },
        },
      )
    : axios.get(
        `/magazine/collection/ordered/?type=${type}&offset=${offset || 0}`,
      );
};

export const getSelectShopItems = (accessToekn: string, offset?: number) => {
  return accessToekn
    ? axios.get(
        `/magazine/collection/ordered/simple/?type=select_shop&offset=${
          offset || 0
        }
`,
        {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
          },
        },
      )
    : axios.get(`/magazine/collection/ordered/simple/?type=select_shop&${
        offset || 0
      }
`);
};

export const getPromotionDetail = (accessToekn: string, id: number) => {
  return accessToekn
    ? axios.get(
        `/magazine/collection/ordered/${id}/
`,
        {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
          },
        },
      )
    : axios.get(`/magazine/collection/ordered/${id}/
`);
};

export const doBookMark = (id: number, is_bookmark: boolean, token: string) => {
  return axios.post(
    `/magazine/${id}/bookmark/`,
    {
      is_bookmark,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const doRate = (id: number, rating: number, token: string) => {
  return axios.post(
    `/magazine/${id}/rating/`,
    {
      rating,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const doLike = (id: number, isLike: boolean, token: string) => {
  return axios.post(
    `/magazine/${id}/like/`,
    {
      isLike,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getMagazineDetailInfo = async (
  id: number,
  token: string | undefined,
) => {
  console.log(token);
  const res = await getMagazine(token);
  const list = res.data as MagazineItem[];
  return list.filter(o => o.id === id)[0];
};

export const getFamousNewzip = () => {
  return axios.get('/magazine/famousnewzip/', {params: {limit: 5}});
};

export const getHomeNewZip = async () => {
  return axios.get('/magazine/collection/newzip/');
};

export const getHomeNewBrands = async () => {
  return axios.get('/magazine/newbrand/');
};

export const requestPayments = async (
  params: PaymentRequestParam,
  token: string,
) => {
  return axios.post('/payments/', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const alterPayments = async (
  id: number,
  params: PaymentRequestParam,
  token: string,
) => {
  return axios.patch(`/payments/${id}/`, params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPayments = async (params: PaymentListParam, token: string) => {
  return axios.get('/payments/', {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPaymentsDetail = async (id: string, token: string) => {
  return axios.get(`/payments/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCollections = async (
  token: string | undefined,
  params: CollectionParam,
) => {
  if (token) {
    return axios.get('/magazine/product/', {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    return axios.get('/magazine/product/', {
      params,
    });
  }
};

export const checkMarketNumber = async (
  token: string | undefined,
  params: MarketNumberParam,
) => {
  const {id, checkCode} = params;
  return axios.patch(
    `/payments/${id}/check`,
    {
      checkCode,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const applyEmptyOptionsNotice = async (
  token: string | undefined,
  body: {phone: string},
  optionId: number,
) => {
  return axios.post(`/magazine/option/${optionId}/entry/`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCurationMagazine = async (token: string | undefined) => {
  if (token) {
    return axios.get('/magazine/brands/set/?type=home', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    return axios.get('/magazine/brands/set/?type=home');
  }
};

export const getBrandSearch = async (
  token: string | undefined,
  name: string,
) => {
  if (token) {
    return axios.get(`/magazine/brands/?limit=20&name=${name}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    return axios.get(`/magazine/brands/?limit=20&name=${name}`);
  }
};
