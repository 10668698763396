import React, {useEffect, useState, useRef} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';
import cc from 'classcat';
import {Capacitor} from '@capacitor/core';
import {Share} from '@capacitor/share';

import {
  MagazineBookMark,
  MagazineBookMarkCount,
  MagazineItem,
  MagazineItemId,
  ToastMessage,
  isOpenToast,
} from '@/store';
import useKakao from '@/hooks/useKakao';
import {UserInfo, user} from '@/store/user';
import {doBookMark} from '@/services/magazine';
import useNative from '@/hooks/useNative';

import styles from './styles.module.scss';

interface Props {
  detailInfo: MagazineItem;
  type: 'brand' | 'zip' | 'news' | 'issue';
  getContentsDetail?: (isUnRefresh?: boolean) => void;
}

export default function ContentsFooter({detailInfo, type}: Props) {
  const kakao = useKakao();
  const navigator = useNavigate();
  const Echo = useNative();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const [isBookmark, setIsBookmark] = useRecoilState(MagazineBookMark);
  const [bookmarkCount, setBookmarkCount] = useRecoilState(
    MagazineBookMarkCount,
  );
  const [magazineItem, setMagazineItem] = useRecoilState(
    MagazineItem({
      id: detailInfo.id,
      type,
    }),
  );
  const userInfo = useRecoilValue(UserInfo);
  const userToken = useRecoilValue(user);
  const setMagazineId = useSetRecoilState(MagazineItemId);

  const [isOpenShare, setIsOpenShare] = useState<boolean>(false);

  const makeUrl = () => {
    const host =
      process.env.NODE_ENV !== 'development'
        ? 'https://knewnew.co.kr'
        : 'https://dev.knewnew.co.kr';
    return `${host}${window.location.pathname}`;
  };

  const copy = async () => {
    const {title, subtitle, imageUrl} = detailInfo;
    console.log(title, subtitle, imageUrl);
    if (Capacitor.getPlatform() === 'web') {
      window.navigator.clipboard.writeText(`${window.location.href}`);
      setToastMessage('링크가 복사되었습니다.');
      setIsOpenToast(true);
    } else {
      await Share.share({
        title: `${title}`,
        text: `${subtitle}`,
        url: `${makeUrl()}`,
      });
    }
    setIsOpenShare(false);
  };

  const shareKakao = async () => {
    const {title, subtitle, imageUrl} = detailInfo;
    if (Capacitor.getPlatform() === 'web') {
      kakao.share({
        title,
        description: subtitle,
        imageUrl,
        link: window.location.href,
      });
    } else {
      await Echo.echo({
        value: `share*${title}*${subtitle}*${imageUrl}*${makeUrl()}*${`${window.location.pathname}`}`,
      });
    }
    setIsOpenShare(false);
  };

  const _doBookmark = async () => {
    try {
      if (userInfo.id == -1) {
        // setOpenLoginModal(true);
        navigator('/login', {
          state: {
            prevPath: `${location.pathname}${location.search}`,
          },
        });
      } else {
        const paths = window.location.pathname.split('/');
        const id = Number(paths[paths.length - 1]);
        const next = !isBookmark;
        await doBookMark(id, next, userToken);
        setMagazineItem({
          ...magazineItem,
          info: {
            ...magazineItem.info,
            isBookmark: next,
            bookmarkCount: bookmarkCount + (!next ? -1 : 1),
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setIsBookmark(detailInfo.isBookmark as boolean);
    setBookmarkCount(detailInfo.bookmarkCount as number);
    return () => setMagazineId(0);
  }, [detailInfo]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef && !wrapperRef?.current?.contains(event.target)) {
      setIsOpenShare(false);
    }
  };

  return (
    <div className={styles.footer}>
      <div
        className={styles.share_items_panel}
        style={{display: isOpenShare ? 'block' : 'none'}}
        ref={wrapperRef}>
        <div className={styles.share_item} onClick={() => shareKakao()}>
          <span className={styles.kakao_icon}>&nbsp;</span>
          <span className={styles.txt}>카카오톡 공유</span>
        </div>
        <div className={styles.share_item} onClick={() => copy()}>
          <span className={styles.link_copy_icon}>&nbsp;</span>
          <span className={styles.txt}>링크 공유</span>
        </div>
      </div>
      <span
        className={styles.share_btn}
        onClick={() => setIsOpenShare(!isOpenShare)}>
        &nbsp;
      </span>
      <div className={styles.bookmark} onClick={() => _doBookmark()}>
        <span
          className={cc([styles.icon, styles.unscrapIcon])}
          style={{display: !isBookmark ? 'inline-block' : 'none'}}>
          &nbsp;
        </span>
        <span
          className={cc([styles.icon, styles.doscrapIcon])}
          style={{display: isBookmark ? 'inline-block' : 'none'}}>
          &nbsp;
        </span>
        <span className={styles.cnt}>{bookmarkCount}</span>
      </div>
    </div>
  );
}
