import React from 'react';
import {useNavigate} from 'react-router-dom';
import {BsChevronRight} from 'react-icons/bs';

import styles from '../styles.module.scss';

interface Props {
  item: CollectionListItem;
  clickable?: boolean;
}

export const BrandGiftItem = ({item, clickable = true}: Props) => {
  const navigator = useNavigate();
  return (
    <div
      className={styles.giftCard}
      style={{cursor: 'pointer'}}
      onClick={() => {
        if (clickable) {
          navigator(`/brand/giftcard/${item.id}`);
        }
      }}>
      <div className={styles.imageContainer}>
        <img
          src={item.imageUrl} // 이미지 링크를 실제 이미지로 대체하세요
          alt="Discount item"
          className={styles.image}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.description}>{item.benefitDesc}</div>
      </div>
      <div className={styles.arrowContainer}>
        <BsChevronRight
          className={styles.arrow}
          size={20}
          fontSize={14}
          color={'#000'}
        />{' '}
        {/* 아이콘으로 대체 */}
      </div>
    </div>
  );
};
