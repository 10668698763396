import React, {useEffect} from 'react';
import {useRecoilState} from 'recoil';
import {useLocation} from 'react-router-dom';

import styles from './styles.module.scss';
import {PathHistories} from '../../store';

interface Props {
  style?: React.CSSProperties;
  children: React.ReactElement;
}

export default function index({children}: Props) {
  const location = useLocation();
  const [pathHistory, setPathHistory] = useRecoilState(PathHistories);

  const checkMagazineListChange = (currentPath: string, prevPath: string) => {
    const _currentPaths = currentPath.split('/');
    const _prevPaths = prevPath.split('/');
    const magazines = ['zip', 'knewbrands', 'news'];
    if (_currentPaths.length === 2 && _prevPaths.length === 2) {
      if (
        magazines.indexOf(_prevPaths[1]) > -1 &&
        magazines.indexOf(_currentPaths[1]) > -1 &&
        _prevPaths[1] !== _currentPaths[1]
      ) {
        return true;
      }
    }
    return false;
  };
  const checkBrandListChange = (currentPath: string, prevPath: string) => {
    const _currentPaths = currentPath.split('/');
    const _prevPaths = prevPath.split('/');
    if (_currentPaths.length > 2 && _prevPaths.length > 2) {
      if (_currentPaths[1] === 'brands' && _prevPaths[1] === 'brands') {
        return true;
      }
    }
    return false;
  };
  const checkBrandBranchChange = (currentPath: string, prevPath: string) => {
    const _currentPaths = currentPath.split('/');
    const _prevPaths = prevPath?.split('/');
    if (_currentPaths.length > 3 && _prevPaths.length > 3) {
      if (_currentPaths[1] === 'brand' && _prevPaths[1] === 'brand') {
        if (_currentPaths[2] === _prevPaths[2]) {
          return true;
        }
      }
    }
    return false;
  };
  useEffect(() => {
    let histories = JSON.parse(JSON.stringify(pathHistory));
    const isRoot =
      (window.location.pathname === '/brand' ||
        window.location.pathname === '/home' ||
        window.location.pathname === '/collection/list' ||
        window.location.pathname === '/community' ||
        window.location.pathname === '/user') &&
      window.location.search === '';
    const lastHistory = histories[histories.length - 1];
    if (isRoot) {
      histories = [];
    }
    if (window.location.pathname === '/login') {
      return;
    }
    /**
     * brands/:address1/:address2 : 브랜드리스트 변경시
     * brand/:id/:placeId : 브랜드 지점 변경시 (id 동일)
     * ['zip', 'knewbrands', 'news'] : 매거진 리스트 이동 시
     */
    if (
      lastHistory &&
      checkMagazineListChange(location.pathname, `/${lastHistory?.path}`)
    ) {
      return;
    }
    if (
      lastHistory &&
      checkBrandListChange(location.pathname, `/${lastHistory?.path}`)
    ) {
      return;
    }
    if (
      lastHistory &&
      checkBrandBranchChange(location.pathname, `/${lastHistory?.path}`)
    ) {
      console.log('incheckbrandbranch');
      return;
    }
    if (
      lastHistory &&
      lastHistory.path ===
        `${location.pathname.replace('/', '')}${location.search}` &&
      !isRoot
    ) {
      histories[histories.length - 1] = {
        path: `${location.pathname.replace('/', '')}${location.search}`,
        state: location.state,
      };
    } else {
      histories.push({
        path: `${location.pathname.replace('/', '')}${location.search}`,
        state: location.state,
      });
    }
    setPathHistory(histories);
  }, [window.location.pathname]);
  return (
    <div className={styles.main} id={'main'}>
      {children}
    </div>
  );
}
