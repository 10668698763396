import {atom, selector, selectorFamily} from 'recoil';
import {AxiosResponse} from 'axios';

import {
  getContentsDetail,
  getCurators,
  getMagazineBanners,
  getMagazine,
  getMagazineDetail,
  getRelatedMagazines,
  getPayments,
  getCurationMagazine,
} from '../services/magazine';
import {loadBannerImages} from '../utils';
import {getCommunities} from '../services/community';
import {user} from './user';

export const HasPayments = atom<boolean>({
  key: 'magazine/payments',
  default: false,
});

export const MagazineTab = atom<string>({
  key: 'magazine/tab',
  default: 'all',
});

export const MagazineDetailInfo = atom<MagazineItem>({
  key: 'magazine/item',
  default: undefined,
});

export const MagazineData = atom<MagazineItem[]>({
  key: 'magazine/data',
  default: [],
});

export const HomeBannerItem = atom<MagazineItem[]>({
  key: 'magazine/home-banner-item',
  default: [],
});

export const MagazineBannerItem = selector<MagazineItem[]>({
  key: 'magazine/magazine-banner-items',
  get: async () => {
    const res = await getMagazineBanners();
    const magazines: MagazineItem[] = res.data[0].magazines;
    await loadBannerImages(magazines.map(m => m.imageUrl));
    return magazines;
  },
});

export const HomeKnewZipCard = atom<NewZipCard[][]>({
  key: 'magazine/knew-zip-card',
  default: [],
});

export const HomeKnewZipIdx = atom<number>({
  key: 'home-zip-index',
  default: 0,
});

export const MagazineZipCards = selector<MagazineItem[]>({
  key: 'magazine/magazine-zip-cards',
  get: async () => {
    const res = await getMagazine(undefined, 4, 'zip');
    const magazines: MagazineItem[] = res.data.results;
    await loadBannerImages(magazines.map(m => m.imageUrl));
    return magazines;
  },
});

export const MagazineBrandCard = selector<MagazineItem>({
  key: 'magazine/magazine-brand-card',
  get: async () => {
    const res = await getMagazine(undefined, 4, 'brands');
    const lastBrand = (res.data.results as MagazineItem[]).splice(0, 1);
    return lastBrand[0];
  },
});

export interface CurationItem {
  title: string;
  desc: string;
  magazine: MagazineItem;
  product: CollectionListItem;
  brand: BrandListItemType;
}

export const HomeKnewBrandCard = selector<CurationItem[]>({
  key: 'magazine/home-knew-brand-card',
  get: async ({get}) => {
    const userToken = get(user);
    const res = await getCurationMagazine(userToken);
    const result = res.data.results as CurationItem[];
    return result;
  },
});

export const HomeKnewNewsCard = selector<MagazineItem[]>({
  key: 'magazine/home-knew-knews-card',
  get: async () => {
    const res = await getMagazine(undefined, 3, 'news');
    const news: MagazineItem[] = res.data.results;
    await loadBannerImages(news.map(m => m.imageUrl));
    return news;
  },
});

export const HomeCommunityRows = selector<Community[][]>({
  key: 'magazine/home-community-cards',
  get: async () => {
    const res: AxiosResponse<CommunityRes> = await getCommunities(undefined, {
      limit: 6,
      offset: 0,
      remove_signout: true,
    });
    const randomRowCnt = Math.ceil(res.data.results.length / 2);
    const rows: Community[][] = [];
    for (let i = 0; i < randomRowCnt; i++) {
      rows.push([...res.data.results].splice(i * 2, 2));
    }
    return rows;
  },
});

export const OrderListItems = selector<OrderListItem[]>({
  key: 'magazine/order-list',
  get: async ({get}) => {
    const userToken = get(user);
    const res: any = await getPayments(
      {
        limit: 20,
        offset: 0,
        type: 'default',
        pagination: 'true',
      },
      userToken,
    );
    return res.data.results;
  },
});

export const BenefitListItems = selector<OrderListItem[]>({
  key: 'magazine/benefits',
  get: async ({get}) => {
    const userToken = get(user);
    const res: any = await getPayments(
      {
        limit: 20,
        offset: 0,
        type: 'benefits',
        pagination: 'true',
      },
      userToken,
    );
    return res.data.results;
  },
});

export const RandomCurators = selector<Curator[]>({
  key: 'magazine/random-curator',
  get: async () => {
    const res = await getCurators(1);
    return (res as Curator[]).splice(0, 4);
  },
});

export const OpenCategoryModal = atom<boolean>({
  key: 'magazine-category-open',
  default: false,
});

export const MagazineItemId = atom<number>({
  key: 'magazine/item-id',
  default: 0,
});

export const MagazineBookMark = atom<boolean>({
  key: 'magazine/my-bookmark',
  default: false,
});

export const MagazineBookMarkCount = atom<number>({
  key: 'magazine/bookmark-count',
  default: 0,
});

export const MagazineImotion = atom<number>({
  key: 'magazine/imotion',
  default: 0,
});

export const PopularAreaList = atom<{txt: string}[]>({
  key: 'magazine/popular-area-list',
  default: [
    {
      txt: '성수',
    },
    {
      txt: '용산',
    },
    {
      txt: '압구정',
    },
    {
      txt: '을지로',
    },
    {
      txt: '종로',
    },
    {
      txt: '망원',
    },
    {
      txt: '삼각지',
    },
    {
      txt: '잠실',
    },
    {
      txt: '문래',
    },
  ],
});

export const MagazineItem = selectorFamily({
  key: 'magazine/item',
  get:
    (param: {id: number; type: 'brand' | 'zip' | 'news' | 'issue'}) =>
    async ({get}) => {
      console.log(param);
      if (param.id > 0) {
        const userToken = get(user);
        const res = await getContentsDetail(userToken, param.id);
        const content = await getMagazineDetail(userToken, param.id);
        const categories =
          param.type === 'brand' ? res.brands?.categoryList : [];

        const relatedMagazines =
          param.type === 'brand' &&
          categories != undefined &&
          categories.length > 0
            ? await getRelatedMagazines(categories)
            : [];
        return {
          info: res,
          file: content,
          relatedItems: relatedMagazines,
        };
      }

      return {
        info: undefined,
        file: '',
        relatedItems: [],
      };
    },
  set:
    param =>
    ({set}, newVal) => {
      set(MagazineBookMark, (newVal as any).info.isBookmark);
      set(MagazineBookMarkCount, (newVal as any).info.bookmarkCount);
      if (param.type === 'news') {
        set(MagazineImotion, (newVal as any).info.myRating || 0);
      }
    },
});
