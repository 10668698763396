import {Capacitor} from '@capacitor/core';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';

import Modal from '@/layouts/Modal';
import {
  BrandMapLocation,
  BrandMapUrl,
  isOpenToast,
  ToastMessage,
} from '@/store';
import {isMobile} from '@/utils';
import useNative from '@/hooks/useNative';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  placeInfo: PlaceInfoProps | undefined;
  close: () => void;
}

export default function LocationBottomSheet({close, placeInfo, isOpen}: Props) {
  const {naver} = window as any;
  const Echo = useNative();
  const mapElement = useRef<HTMLDivElement>(null);
  const routeElement = useRef<HTMLAnchorElement>(null);
  const [userGeoLocation, setUserGeoLocation] = useState<number[]>([]);
  const [mapUrl, setMapUrl] = useRecoilState<string>(BrandMapUrl);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const [locationInfo, setLocationInfo] =
    useRecoilState<number[]>(BrandMapLocation);
  const onGeoloationSucess = (position: any) => {
    if (position && position.coords) {
      setUserGeoLocation(() => {
        return [position.coords.longitude, position.coords.latitude];
      });
    }
  };
  const isWeb = useMemo(() => {
    return Capacitor.getPlatform() === 'web' && !isMobile();
  }, []);
  const onGeolocationError = () => {
    setUserGeoLocation([-1, -1]);
  };
  const copy = async (address: string) => {
    window.navigator.clipboard.writeText(`${address}`);
    setToastMessage('복사되었습니다.');
    setIsOpenToast(true);
  };
  const initMap = () => {
    if (!mapElement.current || !window) {
      return;
    }
    if (placeInfo && mapElement.current && naver.maps) {
      console.log(456);
      naver.maps.Service.geocode(
        {
          address: placeInfo.address,
        },
        (status: any, response: NaverMapGeoCodeResponse) => {
          const {x, y} = response.result.items[1].point;
          setLocationInfo(() => {
            return [Number(x), Number(y)];
          });
        },
      );
    }
  };
  const makeMapUrl = () => {
    let mobileUrl = '';
    let pcUrl = '';
    if (userGeoLocation.length > 0 && userGeoLocation[0] > -1) {
      mobileUrl = `http://m.map.naver.com/route.nhn?menu=route&sname=${'내위치'}&sx=${
        userGeoLocation[0]
      }&sy=${userGeoLocation[1]}&ename='${placeInfo?.name.replaceAll(
        ' ',
        '',
      )}'&ex=${locationInfo[0]}&ey=${locationInfo[1]}&pathType=0&showMap=true`;
      pcUrl = `http://map.naver.com/index.nhn?slng=${userGeoLocation[0]}&slat=${
        userGeoLocation[1]
      }&stext='내위치'&elng=${locationInfo[0]}&elat=${
        locationInfo[1]
      }&etext='${placeInfo?.name.replaceAll(' ', '')}'&menu=route&pathType=1`;
    } else {
      mobileUrl = `http://m.map.naver.com/route.nhn?menu=route&ename='${placeInfo?.name.replaceAll(
        ' ',
        '',
      )}'&ex=${locationInfo[0]}&ey=${locationInfo[1]}&pathType=0&showMap=true`;
      pcUrl = `http://map.naver.com/index.nhn?elng=${locationInfo[0]}&elat=${
        locationInfo[1]
      }&etext='${placeInfo?.name.replaceAll(' ', '')}'&menu=route&pathType=1`;
    }
    setMapUrl(isMobile() ? mobileUrl : pcUrl);
  };
  const openNaverMapAppOnMW = () => {
    location.href = `nmap://route/car?dlat=${locationInfo[0]}&dlng=${locationInfo[1]}&dname=${placeInfo?.name}&appname=knewnew`;
  };
  const moveToNaverMap = () => {
    if (Capacitor.getPlatform() === 'web') {
      openNaverMapAppOnMW();
    } else {
      Echo.echo({
        value: `map*${locationInfo[1]}*${locationInfo[0]}*${placeInfo?.name}`,
      });
    }
  };

  useEffect(() => {
    if (placeInfo && mapElement.current) {
      console.log('init map');
      initMap();
    }
    // 웹일경우 geoloation api로 현재위치 받아오기
    if (Capacitor.getPlatform() === 'web') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          onGeoloationSucess,
          onGeolocationError,
        );
      }
    }
  }, [placeInfo, mapElement]);

  useEffect(() => {
    if (locationInfo.length === 2) {
      let map: any = null;
      const location = new naver.maps.LatLng(locationInfo[1], locationInfo[0]);
      const mapOptions = {
        center: location,
        zoom: 18,
        zoomControl: false,
      };
      map = new naver.maps.Map('map', mapOptions);
      new naver.maps.Marker({
        position: location,
        map,
      });

      makeMapUrl();
    }
  }, [locationInfo, userGeoLocation]);

  return (
    <Modal.BottomSheet
      isOpen={isOpen}
      setIsOpen={close}
      title="매장 위치"
      height={Capacitor.getPlatform() === 'ios' ? 'auto' : '490px'}>
      <>
        <div>
          <div ref={mapElement} className={styles.mapArea} id="map"></div>
          {placeInfo && (
            <>
              <div className={`${styles.placeInfo} ${styles.tit}`}>
                {placeInfo.name}
              </div>
              <div className={`${styles.placeInfo} ${styles.address}`}>
                <span className={styles.addressTxt}>{placeInfo.address}</span>
                <span
                  className={styles.copy}
                  onClick={() => copy(placeInfo.address)}>
                  복사
                </span>
              </div>
              <div className={`${styles.placeInfo} ${styles.running}`}>
                {placeInfo.openingHours.map(o => {
                  return <div key={Math.random()}>{o}</div>;
                })}
              </div>
              <div style={{padding: '0'}}>
                {isWeb ? (
                  <a
                    ref={routeElement}
                    className={styles.findMapBtn}
                    href={`${mapUrl}`}
                    target="_blank"
                    rel="noreferrer">
                    <span className={styles.txt}>매장 길찾기</span>
                    <span className={styles.icon}></span>
                  </a>
                ) : (
                  <button
                    className={styles.findMapBtn}
                    onClick={() => moveToNaverMap()}>
                    <span className={styles.txt}>매장 길찾기</span>
                    <span className={styles.icon}></span>
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </>
    </Modal.BottomSheet>
  );
}
