import {Capacitor} from '@capacitor/core';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {Share} from '@capacitor/share';

import useNative from './useNative';
import {LoginPrevPath, ToastMessage, isOpenToast} from '../store';

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function useKakao() {
  const {Kakao} = window as any;
  const Echo = useNative();
  const prevPath = useRecoilValue(LoginPrevPath);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);

  const login = () => {
    const origin = window.location.origin;
    localStorage.setItem('knewnew_kakao_redirect', prevPath);
    Kakao.Auth.authorize({
      redirectUri: `${origin}/login/auth`,
    });
  };
  const getStatusInfo = () => {
    return new Promise((resolve, reject) => {
      Kakao.Auth.getStatusInfo()
        .then(function (res: any) {
          if (res.status === 'connected') {
            resolve(Kakao.Auth.getAccessToken());
          }
        })
        .catch(function (err: any) {
          Kakao.Auth.setAccessToken(null);
          reject(err);
        });
    });
  };
  const getShareingTemplate = ({
    title,
    description,
    imageUrl,
    link,
  }: {
    title: string;
    description: string;
    imageUrl: string;
    link: string;
  }) => {
    return {
      objectType: 'feed',
      content: {
        title,
        description,
        imageUrl,
        link: {
          // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
          mobileWebUrl: link,
          webUrl: link,
        },
      },
      buttons: [
        {
          title: '앱으로 보기',
          link: {
            mobileWebUrl: `knewnew://${location.pathname}`,
            webUrl: link,
          },
        },
        {
          title: '웹으로 보기',
          link: {
            mobileWebUrl: link,
            webUrl: link,
          },
        },
      ],
    };
  };

  const share = ({
    title,
    description,
    imageUrl,
    link,
  }: {
    title: string;
    description: string;
    imageUrl: string;
    link: string;
  }) => {
    Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title,
        description,
        imageUrl,
        link: {
          // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
          mobileWebUrl: link,
          webUrl: link,
        },
      },
      buttons: [
        {
          title: '앱으로 보기',
          link: {
            mobileWebUrl: `knewnew://${location.pathname}`,
            webUrl: link,
          },
        },
        {
          title: '웹으로 보기',
          link: {
            mobileWebUrl: link,
            webUrl: link,
          },
        },
      ],
    });
  };

  const shareOrder = ({
    title,
    imageUrl,
    link,
    items,
  }: {
    title: string;
    imageUrl: string;
    link: string;
    items: any[];
  }) => {
    Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: '',
        imageUrl,
        link: {
          // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
          mobileWebUrl: link,
          webUrl: link,
        },
      },
      itemContent: {
        profileText: title,
        items,
      },
      buttons: [
        {
          title: '앱으로 보기',
          link: {
            mobileWebUrl: `knewnew://${location.pathname}`,
            webUrl: link,
          },
        },
        {
          title: '웹으로 보기',
          link: {
            mobileWebUrl: link,
            webUrl: link,
          },
        },
      ],
    });
  };
  const makeUrl = () => {
    const host =
      process.env.NODE_ENV !== 'development'
        ? 'https://knewnew.co.kr'
        : 'https://dev.knewnew.co.kr';
    return `${host}${window.location.pathname}`;
  };

  const shareKakao = async (
    url: string,
    title: string,
    subtitle: string,
    imageUrl: string,
  ) => {
    // sender({
    //   _msg: 'floating_share_click',
    //   _screen: window.location.pathname,
    //   _action: Action.CLICK,
    // });
    if (Capacitor.getPlatform() === 'web') {
      console.log(subtitle);
      share({
        title,
        imageUrl,
        description: subtitle || '',
        link: window.location.href,
      });
    } else {
      await Echo.echo({
        value: `share*${title}*${subtitle}*${imageUrl}*${makeUrl()}*${`${url}`}`,
      });
    }
  };

  const copyLink = async (url: string, title: string, subtitle: string) => {
    if (Capacitor.getPlatform() === 'web') {
      window.navigator.clipboard.writeText(`${url}`);
      setToastMessage('링크가 복사되었습니다.');
      setIsOpenToast(true);
    } else {
      await Share.share({
        title: `${title}`,
        text: `${subtitle}`,
        url: makeUrl(),
      });
    }
  };

  return {
    login,
    getStatusInfo,
    share,
    getShareingTemplate,
    shareKakao,
    copyLink,
    shareOrder,
  };
}
