import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {getBrandMagazine} from '@/services/brands';

import styles from './magazine.styles.module.scss';

interface props {
  args: {
    id?: string;
  };
}
export default function index({args: {id}}: props) {
  const [magazineList, setMagazineList] = useState<MagazineItem[]>([]);
  const navigator = useNavigate();
  const location = useLocation();
  const categoryMap = {
    brands: {name: 'BRAND', url: '/knewbrands/'},
    zip: {name: 'PICK', url: '/zip/'},
    news: {name: 'NEWS', url: '/news/'},
    showroom: {name: 'showRoom', url: '/news/'},
  };
  const init = async () => {
    try {
      const {data} = await getBrandMagazine(Number(id));
      setMagazineList(data?.results);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    init();
  }, []);
  return magazineList?.length > 0 ? (
    <div className={styles.wrapper}>
      <div className={styles.title}>MAGAZINE</div>
      <div className={styles.magazineWrapper}>
        {magazineList.slice(0, 3).map((item, index) => (
          <div key={`magazine-item${index}`} className={styles.magazineItem}>
            <img src={item.imageUrl} alt="" />
            <div
              className={styles.textArea}
              onClick={() => {
                navigator(
                  `${(categoryMap[item.category!] ?? categoryMap.brands)?.url}${
                    item.id
                  }`,
                  {
                    replace: true,
                    state: location.state,
                  },
                );
              }}
              style={{cursor: 'pointer'}}>
              <div className={styles.itemHeader}>
                MAGAZINE
                <span>{categoryMap[item.category ?? 'brands']?.name}</span>
              </div>
              <div className={styles.itemTitle}>{item.title}</div>
            </div>
          </div>
        ))}
      </div>
      {/* <div style={{padding: '30px 0 40px'}}>
        <button
          className={styles.collectionViewMoreBtn}
          onClick={() => navigator('/knewbrands')}>
          <span className={styles.txt}>다른 매거진 보기</span>
          <span className={styles.collectionViewMoreIcon}></span>
        </button>
      </div> */}
    </div>
  ) : (
    <></>
  );
}
