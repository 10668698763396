import React, {useEffect, useRef, useState} from 'react';
import {
  PaymentWidgetInstance,
  loadPaymentWidget,
} from '@tosspayments/payment-widget-sdk';
import {nanoid} from 'nanoid';
import {useNavigate} from 'react-router-dom';

import ContentsHeader from '@/layouts/ContentsHeader';

const selector = '#payment-widget';
const clientKey = 'test_ck_mnRQoOaPz8LGv2zNdjvry47BMw6v';
const customerKey = 'YbX2HuSlsC9uVJW6NMRMj';

export default function Payment() {
  const navigator = useNavigate();
  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null);
  const paymentMethodsWidgetRef = useRef<ReturnType<
    PaymentWidgetInstance['renderPaymentMethods']
  > | null>(null);
  const [price] = useState(50_000);

  useEffect(() => {
    (async () => {
      // ------  결제위젯 초기화 ------
      // 비회원 결제에는 customerKey 대신 ANONYMOUS를 사용하세요.
      const paymentWidget = await loadPaymentWidget(clientKey, customerKey); // 회원 결제
      // const paymentWidget = await loadPaymentWidget(clientKey, ANONYMOUS); // 비회원 결제

      // ------  결제위젯 렌더링 ------
      // https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        selector,
        {value: price},
      );

      // ------  이용약관 렌더링 ------
      // https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자
      paymentWidget.renderAgreement('#agreement');

      paymentWidgetRef.current = paymentWidget;
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
  }, []);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    // ------ 금액 업데이트 ------
    // https://docs.tosspayments.com/reference/widget-sdk#updateamount결제-금액
    paymentMethodsWidget.updateAmount(
      price,
      paymentMethodsWidget.UPDATE_REASON.COUPON,
    );
  }, [price]);
  return (
    <div className="main" style={{height: '100vh', position: 'relative'}}>
      <ContentsHeader txt={'결제하기'} isOption={'none'} isBottomLine={''} />
      <span>{`${price.toLocaleString()}원`}</span>
      <div id="payment-widget" />
      <div id="agreement" />
      <div
        className="filter-modal-footer"
        style={{
          position: 'absolute',
          bottom: '20px',
          left: '20px',
          right: '20px',
        }}>
        <button
          className="modal btn black-filled"
          disabled={false}
          onClick={async () => {
            const paymentWidget = paymentWidgetRef.current;
            try {
              // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
              // https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
              await paymentWidget?.requestPayment({
                orderId: nanoid(),
                orderName: '토스 티셔츠 외 2건',
                customerName: '김토스',
                customerEmail: 'customer123@gmail.com',
                successUrl: `knewnew://order/success`,
                failUrl: `knewnew://order/fail`,
              });
            } catch (error) {
              // 에러 처리하기
              navigator('/order/fail?message="결제 취소"');
            }
          }}>
          결제하기
        </button>
      </div>
    </div>
  );
}
